import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/hanne/src/HS/strautmann.dev/src/templates/markdown-page.js";
import Button from "../components/button";
import Textmarker from "../components/textmarker";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hallo, ich bin Hannes Strautmann.`}<br />{`Ich helfe Unternehmen bei`}<br /><Textmarker mdxType="Textmarker">{`Internet of Things`}</Textmarker>{` und `}<Textmarker mdxType="Textmarker">{`Digitalisierung`}</Textmarker>{`.`}</h1>
    <ul>
      <li parentName="ul">{`Komplette IoT-Lösungen. Von der SPS bis zur Kunden-App. Serverless und skalierbar.`}</li>
      <li parentName="ul">{`Digitalisierung von Prozessen, mit und ohne Cloud.`}</li>
      <li parentName="ul">{`Entwicklung und Wartung von Windows Desktop Anwendungen (C# / .Net)`}</li>
      <li parentName="ul">{`Beratung zum Software-Entwicklungs-Prozess (GitFlow, CI/CD, Github/GitLab, Jenkins)`}
        {/* - Digitalisierung */}
        {/* - Beratung zu Remote Work (aus eigener <a href="/erfahrung/" class="!text-purple-800 !no-underline hover:!text-purple-600">Erfahrung</a>) */}
      </li>
    </ul>
    {/* TODO: etwas mehr margin */}
    <p>
  <Button link="/kontakt/" text="Unverbindliche Anfrage" primary mdxType="Button" />
    </p>
    {/* <Link to="/typography/">Ein Test-Link zu "Typography"</Link> */}
    {/* Integration von Business-Software (ERP, DocuWare, etc.) */}
    {/* Webseite mit WordPress oder statisch / Gatsby */}
    {/* Beratung: Web, Linux Server, Office 365, AWS */}
    {/* Strong communication skills */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      